<template>
  <div class="form__banner">
    <div
      class="banner__main tw-relative"
      :class="{ 'banner--removable': banner.src }"
    >
      <img
        v-if="banner.src"
        class="banner__img"
        :src="banner.src"
        alt="Form Banner"
        @error="(e) => (banner.src = null)"
      >
      <div
        v-else
        class="banner__img tw-w-full tw-w-full tw-flex tw-justify-center tw-items-center"
      >
        <p class="tw-text-xl tw-text-muted">{{ $t('No Banner Set') }}</p>
      </div>
      <template>
        <div
          v-show="banner.src"
          id="banner__remove-img"
          class="banner__action action__remove tw-absolute tw-m-1 tw-top-0 tw-right-0 tw-cursor-pointer"
          @click="removeImage"
        >
          <feather-icon icon="Trash2Icon" color="white" />
        </div>
        <b-tooltip
          target="banner__remove-img"
          :title="$t('Remove banner image')"
          placement="left"
        />
      </template>
      <template>
        <div
          id="banner__add-img"
          class="banner__action action__add-img tw-absolute tw-m-1 tw-top-0 tw-cursor-pointer"
          @click="$refs.fileExplorer.click()"
        >
          <img src="@/assets/images/icons/add-image.svg" alt="Add Image">
        </div>
        <b-tooltip
          target="banner__add-img"
          :title="$t('Upload banner image')"
          placement="left"
        />
      </template>
    </div>
    <span v-if="banner.error" class="invalid-feedback tw-py-1 tw-px-2">
      {{ this.$t(banner.error) }}
    </span>
    <input
      ref="fileExplorer"
      type="file"
      class="tw-hidden"
      accept=".jpg, .jpeg, .png, .gif"
      @change="handleBannerImageChange"
    >
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { fileToDataURL } from '@/helpers/app'
import { defaultBanner } from '@/store/form/state'

export default {
  props: {
    src: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      timeout: null,
      banner: { ...defaultBanner() },
    }
  },

  watch: {
    banner: {
      handler(value) {
        this.doDebounce('timeout', () => this.setBanner(value), 300)
      },

      deep: true,
      immediate: true,
    },

    src: {
      handler(src) {
        if (src) {
          this.banner = {
            error: null,
            file: null,
            src,
          }
        }
      },

      immediate: true,
    },
  },

  methods: {
    ...mapMutations({
      setBanner: 'form/SET_BANNER',
      setRemoveBannerFlag: 'form/SET_REMOVE_BANNER_FLAG',
    }),

    handleBannerImageChange(e) {
      const [file] = e.target.files
      const sizeInMb = +(file.size / (1000 * 1000)).toFixed(2)

      this.banner.error = null
      if (sizeInMb > 10) {
        this.banner.error = 'Allowed JPG, GIF or PNG. Max size of 10MB.'
        return null
      }

      fileToDataURL(file, dataURL => {
        this.banner.src = dataURL
        this.banner.file = file
      })

      e.target.value = null
    },

    removeImage() {
      this.banner = { ...defaultBanner() }
      this.setRemoveBannerFlag(true)
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/forms.scss';

.form__banner {
  .banner__main {
    &.banner--removable {
      background: red;
      .action__add-img {
        top: 45px;
      }
    }
  }

  .banner__img {
    height: 150px;
    width: 100%;
    object-fit: cover;
    background-color: $forms__banner-bg;
  }

  .banner__action {
    background: $forms__banner__add-img-bg;
    border-radius: 5px;
    padding: 4px 4px 6px 6px;
    transition: background 0.15s linear;
    right: 0;

    &:hover {
      background: $forms__banner__add-img-bg--hovered;
    }

    img {
      height: 25px;
      width: 25px;
    }

    &.action__remove {
      padding: 7.5px;
    }

    .feather {
      height: 20px;
      width: 20px;
    }
  }
}
</style>
