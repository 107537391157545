import * as yup from 'yup'
import CustomFieldTypes, { customFieldValidations } from '@/constants/customFieldTypes'
import { FieldType } from '@/constants/form'

export const questionnaireFormSchema = fieldInputs => {
  const rules = {
    ...fieldInputs.reduce((schema, input) => {
      if (input.fieldType === FieldType.QUESTION) {
        const prefix = `${input.entitySchema}_${input.entityKey}_${input.key}`

        schema[`${prefix}_title`] = yup.string().nullable().required().max(100, 'Max 100 limit')
        let valueRule = customFieldValidations(yup)[input.type]()

        if (input.type === CustomFieldTypes.Text) {
          valueRule = valueRule.max(200, 'Max 200 limit')
        }

        if (input.hasDefaultValue) {
          valueRule = valueRule.required()
        }

        schema[`${prefix}_value`] = valueRule
      }

      if (input.fieldType === FieldType.TITLE) {
        schema[`${input.fieldId}_title`] = yup.string().nullable().required().max(100, 'Max 100 limit')
      }

      return schema
    }, {}),
  }

  rules.title = yup.string().nullable().required().max(100, 'Max 100 limit')
  rules.questionsLength = yup.number().min(1, 'at-least-1-question-required')

  return yup.object().shape(rules)
}

export const thankYouFormSchema = yup.object().shape({
  thankYouPageTitle: yup.string().nullable().required().max(100, 'Max 100 limit'),
  thankYouPageParagraph: yup.string().nullable().required(),
})

export const submitButtonConfigSchema = yup.object().shape({
  label: yup.string().nullable().required().max(100, 'Max 100 limit'),
  bgColor: yup.string()
    .required()
    .trim()
    .matches(/^#[0-9A-F]{6}$/i, 'Invalid HEX Color'),
})
