<template>
  <b-modal
    id="form__share-link-modal"
    v-model="open"
    hide-footer
    centered
    no-fade
    size="sm"
  >
    <div v-if="url" class="modal__body tw-p-8">
      <div class="modal__header mb-2">
        <h4 class="mb-1 modal__heading">
          <feather-icon icon="Share2Icon" class="mr-1" />
          {{ $t('Share this form') }}
        </h4>
        <hr class="my-0">
      </div>
      <p>{{ $t('form-share-text') }}:</p>
      <div class="form__share-link tw-relative p-1 tw-flex border">
        <feather-icon icon="LinkIcon" class="tw-mr-2 tw-flex-shrink-0" />
        <input
          v-model="url"
          class="tw-outline-none tw-w-full tw-bg-transparent border-0 tw-text-text-primary"
          type="text"
          readonly
          @click="(e) => e.target.select()"
        >
        <ProzessCopyToClipboard
          id="copy-form-share-link"
          clipboard-position="static"
          class="tw-flex  tw-items-center tw-flex-shrink-0 tw-ml-4"
          :hidden="false"
          :text="url"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import RootEvents from '@/constants/rootEvents'
import ProzessCopyToClipboard from '@/@core/components/ProzessCopyToClipboard.vue'

export default {
  components: {
    ProzessCopyToClipboard,
  },

  data() {
    return {
      open: false,
      url: null,
    }
  },

  mounted() {
    this.registerBusEvent(
      RootEvents.FORMS_TOGGLE_SHARE_MODAL,
      ({ open, url }) => {
        this.open = open ?? true
        this.url = url
      },
    )
  },

  destroyed() {
    this.$root.$off(RootEvents.FORMS_TOGGLE_SHARE_MODAL)
  },

  methods: {
    close() {
      this.open = false
    },
  },
}
</script>
