<template>
  <div class="form__title tw-flex tw-pb-4 tw-pr-4">
    <div class="title__handle tw-px-3">
      <ProzessDragHandle :draggable="draggable" class="tw-mt-4" />
    </div>
    <div class="title__fields tw-flex tw-flex-col tw-w-full">
      <div class="fields__title tw-text-dark tw-flex tw-items-center">
        <input
          type="text"
          :value="field.title"
          :placeholder="$t(titlePlaceholder)"
          class="border-0 tw-outline-none tw-w-full tw-font-bold tw-text-xs tw-bg-transparent"
          @input="(e) => $emit('update:title', e.target.value)"
        >
        <span class="tw-flex-shrink-0 tw-flex">
          <small
            class="tw-pl-4 tw-text-muted tw-font-medium tw-text-2-1/2xs"
            :class="{ 'text-danger': error }"
          >
            *{{ error ? $t(error) : $t('Required') }}
          </small>
          <template v-if="removeable">
            <feather-icon
              :id="`delete-icon--${field.fieldId}`"
              class="mx-1 tw-cursor-pointer"
              icon="TrashIcon"
              @click="$emit('remove', field.fieldId)"
            />
            <b-tooltip
              :target="`delete-icon--${field.fieldId}`"
              :title="$t('Remove')"
              placement="left"
            />
          </template>
        </span>
      </div>
      <div class="fields__description tw-text-dark">
        <textarea
          :value="field.description"
          :placeholder="$t(descriptionPlaceholder)"
          rows="3"
          class="border-0 tw-outline-none tw-resize-none tw-w-full tw-bg-transparent"
          @input="(e) => $emit('update:description', e.target.value)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProzessDragHandle from '@/components/shared/ProzessDragHandle.vue'

export default {
  components: {
    ProzessDragHandle,
  },

  props: {
    draggable: {
      type: Boolean,
      default: true,
    },

    field: {
      type: Object,
      required: true,
    },

    removeable: Boolean,

    error: {
      type: String,
      default: null,
    },

    titlePlaceholder: {
      type: String,
      default: 'Title',
    },

    descriptionPlaceholder: {
      type: String,
      default: 'Description',
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/forms.scss';

.form__title {
  background: $forms__row-bg;
  border-radius: 6px;

  .title__fields {
    border: 1px solid $forms__border;
    border-radius: 6px;
    overflow: hidden;

    .fields__title {
      background: $forms__field-bg;
      padding: 8px;
      border-bottom: 1px dashed rgba($forms__border, 0.5);
    }

    .fields__description {
      background: $forms__field-bg;
      padding: 8px;
    }
  }
}
</style>
