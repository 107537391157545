<template>
  <b-dropdown :id="id" class="forms__contact-field-linker">
    <template #button-content>
      <feather-icon
        class="mx-1 tw-cursor-pointer"
        :class="nativeValue ? 'tw-text-primary' : 'tw-text-dark'"
        icon="Link2Icon"
        size="16"
      />
    </template>
    <b-dropdown-item @click="handleClick(null)">
      {{ $t('None') }}
    </b-dropdown-item>
    <b-dropdown-item
      v-for="(item, i) in matchingFields"
      :key="i"
      @click="handleClick(item.key)"
    >
      {{ $t(item.label) }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapState } from 'vuex'
import { getLinkableFieldTypes } from '@/helpers/form'
import { Entity, Schema } from '@/constants/app'

export default {
  props: {
    value: {
      type: String,
      default: null,
    },

    id: {
      type: String,
      default: null,
    },

    fieldType: {
      type: String,
      default: null,
    },

    fieldId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      nativeValue: null,
    }
  },

  computed: {
    ...mapState({
      userFields: ({ user }) => user.fields,
    }),

    matchingFields() {
      if (this.fieldId === `${Schema.CRM}.${Entity.COMPANY}.name`) {
        return this.userFields.filter(({ key }) => key === 'systemCompanyName')
      }

      const allowedTypes = getLinkableFieldTypes(this.fieldType)

      return this.userFields.filter(
        ({ dataType }) => !allowedTypes.length || allowedTypes.includes(dataType),
      )
    },
  },

  watch: {
    value: {
      handler(value) {
        this.nativeValue = value
      },

      immediate: true,
    },
  },

  methods: {
    handleClick(selection) {
      this.nativeValue = selection
      this.$emit('input', selection)
    },
  },
}
</script>

<style lang="scss">
.forms__contact-field-linker {
  .btn {
    padding: 0 !important;
    background-color: transparent !important;
    border: none;
    box-shadow: none !important;
    &:after {
      display: none;
    }
  }
}
</style>
