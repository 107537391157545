<template>
  <div id="forms__questionnaire" class="forms__editor tw-mt-4">
    <div class="tw-mb-4 tw-flex tw-justify-between">
      <h4 class="mb-0 tw-text-body">{{ $t('Form Editor') }}</h4>
    </div>
    <div
      class="column__content tw-flex tw-flex-col tw-justify-between tw-overflow-auto tw-rounded-lg tw-relative"
    >
      <div v-if="viewMode" class="selectors__backdrop tw-absolute" />
      <div>
        <FormBanner :src="bannerSrc" />
        <div class="column__actions tw-flex tw-justify-end">
          <span
            class="forms__accessibility-switch tw-inline-flex tw-items-center"
          >
            <label
              class="tw-text-xs tw-mt-2 tw-text-dark tw-cursor-pointer"
              @click="handleAccessibilityChange"
            >
              {{ $t('Anyone can respond?') }}
            </label>
            <b-form-checkbox
              :checked="form.open"
              switch
              inline
              @change="handleAccessibilityChange"
            />
          </span>
        </div>
        <div class="column__fields">
          <FormTitleField
            title-placeholder="Form Title"
            :field="form"
            :draggable="false"
            class="tw-mb-2"
            :error="$hasError('title')"
            @update:title="(e) => (form.title = e)"
            @update:description="(e) => (form.description = e)"
          />
          <p
            v-if="!questionsLength && $hasError('questionsLength')"
            class="tw-text-xs mt-5 text-danger text-center"
          >
            {{ $t($hasError('questionsLength')) }}
          </p>
          <p
            v-if="noInquiryContact"
            class="tw-text-xs mt-5 text-warning text-center"
          >
            {{ $t('no-inquiry-contact-warning') }}
          </p>
          <p
            v-if="noTodoContact"
            class="tw-text-xs mt-5 text-warning text-center"
          >
            {{ $t('no-todo-contact-warning') }}
          </p>
          <p
            v-if="noCompanyContactRelationship"
            class="tw-text-xs mt-5 text-warning text-center"
          >
            {{ $t('Company - Contact Relationship Entity must also be selected.') }}
          </p>
          <draggable
            :list="fieldInputs"
            class="list-group"
            ghost-class="ghost"
            :animation="150"
            handle=".pz__drag-handle"
          >
            <template v-for="(item, i) in fieldInputs">
              <FormQuestionField
                v-if="item.fieldType === FieldType.QUESTION"
                :key="`question-field-${i}`"
                :field="item"
                draggable
                :value-error="getError(item, 'value')"
                :title-error="getError(item, 'title')"
                class="tw-mb-2"
                @update:title="(e) => (item.title = e)"
                @update:defaultValue="(e) => (item.defaultValue = e)"
                @update:mergeWith="(e) => (item.mergeWith = e)"
                @update:hidden="(e) => (item.hidden = e)"
                @update:hasDefaultValue="(e) => (item.hasDefaultValue = e)"
                @remove="handleQuestionFieldRemoval"
              />
              <FormTitleField
                v-else-if="item.fieldType === FieldType.TITLE"
                :key="`title-field-${i}`"
                :field="item"
                class="mt-5 tw-mb-2"
                :error="$hasError(`${item.fieldId}_title`)"
                removeable
                @remove="handleTitleFieldRemoval"
                @update:title="(e) => (item.title = e)"
                @update:description="(e) => (item.description = e)"
              />
            </template>
          </draggable>
        </div>
      </div>
      <div class="forms__footer mb-3">
        <div class="text-center tw-text-dark tw-font-medium">
          <span
            class="tw-inline-flex tw-items-center tw-justify-center tw-cursor-pointer hover:tw-text-primary"
            @click="addTitleBlock()"
          >
            <feather-icon icon="PlusCircleIcon" class="mx-1" />
            <span class="tw-text-base">
              {{ $t('Add New Title / Paragraph') }}
            </span>
          </span>
        </div>
        <div class="forms__submit-btn text-center mt-4 px-1">
          <button
            id="submit-btn-config"
            class="btn tw-w-full hover:tw-bg-lighten tw-text-sm tw-py-4 shadow-sm"
            :style="submitBtnStyle"
            @click="toggleSubmitConfigModal({ open: true })"
          >
            <feather-icon icon="SlidersIcon" class="mr-1" />
            {{ form.submitLabelText }}
          </button>
          <b-tooltip
            target="submit-btn-config"
            :title="$t('Customize Submit Button')"
            placement="top"
          />
        </div>
      </div>
    </div>
    <FormSubmitConfigModal :save-callback="handleSubmitButtonUpdate" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import Draggable from 'vuedraggable'
import { defaultForm } from '@/store/form/state'
import { FieldType } from '@/constants/form'
import { Schema } from '@/constants/app'
import FormBanner from '@/components/shared/FormBanner.vue'
import FormTitleField from '@/components/Forms/FormTitleField.vue'
import FormQuestionField from '@/components/Forms/FormQuestionField.vue'
import RootEvents from '@/constants/rootEvents'
import FormSubmitConfigModal from '@/components/Forms/FormSubmitConfigModal.vue'
import CustomFieldTypes from '@/constants/customFieldTypes'

export default {
  components: {
    FormBanner,
    FormTitleField,
    FormQuestionField,
    Draggable,
    FormSubmitConfigModal,
  },

  props: {
    draggable: Boolean,
    viewMode: Boolean,

    errors: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      FieldType,

      form: { ...defaultForm() },

      fieldInputs: [],

      formTimeout: null,
      fieldInputsTimeout: null,
      bannerSrc: null,
    }
  },

  computed: {
    ...mapState({
      resource: ({ form }) => form.resource,
      entities: ({ form }) => form.entities,
      fieldOptions: ({ form }) => form.fieldOptions,
    }),

    ...mapGetters({
      noInquiryContact: 'form/noInquiryContact',
      noTodoContact: 'form/noTodoContact',
      noCompanyContactRelationship: 'form/noCompanyContactRelationship',
    }),

    submitBtnStyle() {
      return {
        color: this.form.submitLabelColorCode,
        backgroundColor: this.form.submitButtonColorCode,
      }
    },

    questionsLength() {
      return this.fieldInputs.filter(
        ({ fieldType }) => fieldType === FieldType.QUESTION,
      ).length
    },
  },

  watch: {
    form: {
      handler(value) {
        this.doDebounce('formTimeout', () => this.setForm(value), 300)
      },

      deep: true,
      immediate: true,
    },

    fieldInputs: {
      handler(value) {
        this.doDebounce(
          'fieldInputsTimeout',
          () => this.setFieldInputs(value),
          300,
        )
      },

      deep: true,
      immediate: true,
    },
  },

  mounted() {
    this.registerBusEvent(
      RootEvents.FORMS_ADD_SELECTED_ENTITY,
      this.handleEntitySelection,
    )

    this.registerBusEvent(
      RootEvents.FORMS_REMOVE_SELECTED_ENTITY,
      this.handleEntityRemoval,
    )

    this.registerBusEvent(
      RootEvents.FORMS_ADD_SELECTED_FIELD,
      this.handleFieldSelection,
    )

    this.registerBusEvent(
      RootEvents.FORMS_REMOVE_SELECTED_FIELD,
      this.handleFieldRemoval,
    )

    this.registerBusEvent(
      RootEvents.FORMS_SELECT_ALL_FIELD_OPTIONS,
      this.handleAllFieldsSelection,
    )

    this.registerBusEvent(
      RootEvents.FORMS_DESELECT_ALL_FIELD_OPTIONS,
      this.handleAllFieldsRemoval,
    )

    this.$root.$on(RootEvents.FORMS_PREFILL_DATA, e => {
      this.$nextTick(() => this.prefillQuestionnaire(e))
    })
  },

  destroyed() {
    this.$root.$off(RootEvents.FORMS_ADD_SELECTED_ENTITY)
    this.$root.$off(RootEvents.FORMS_REMOVE_SELECTED_ENTITY)
    this.$root.$off(RootEvents.FORMS_ADD_SELECTED_FIELD)
    this.$root.$off(RootEvents.FORMS_REMOVE_SELECTED_FIELD)
    this.$root.$off(RootEvents.FORMS_SELECT_ALL_FIELD_OPTIONS)
    this.$root.$off(RootEvents.FORMS_DESELECT_ALL_FIELD_OPTIONS)
  },

  methods: {
    ...mapMutations({
      addSelectedField: 'form/ADD_SELECTED_FIELD',
      setForm: 'form/SET_FORM',
      setFieldInputs: 'form/SET_FIELD_INPUTS',
      removeSelectedField: 'form/REMOVE_SELECTED_FIELD',
      toggleCollapsedState: 'form/TOGGLE_OPTION_COLLAPSED_STATE',
      setLastSavedFieldInputs: 'form/SET_LAST_SAVED_FIELD_INPUTS',
      unlinkFields: 'form/UNLINK_FIELDS',
    }),

    addTitleBlock(forcedData = {}) {
      this.fieldInputs.push({
        title: null,
        description: null,
        fieldId: this.$uuidv4(),
        fieldType: FieldType.TITLE,
        ...forcedData,
      })
    },

    fieldDefaultValue(field) {
      return field.type === CustomFieldTypes.Bool ? false : null
    },

    fieldOptionEntity(entityId) {
      return this.fieldOptions.find(option => option.entityId === entityId)
    },

    getError(item, type) {
      return this.$hasError(
        `${item.entitySchema}_${item.entityKey}_${item.key}_${type}`,
      )
    },

    handleAllFieldsSelection(entityId) {
      const entity = this.fieldOptionEntity(entityId)

      entity.fields.forEach(field => {
        const alreadySelected = this.fieldInputs.some(
          input => input.entityId === entityId && input.key === field.key,
        )

        if (!alreadySelected) {
          this.fieldInputs.push({
            ...field,
            title: this.$t(field.label),
            entityId,
            schemaKey: entity.schema,
            tableKey: entity.key,
            fieldKey: field.key,
            hidden: false,
            hasDefaultValue: false,
            defaultValue: this.fieldDefaultValue(field),
            fieldId: `${entityId}.${field.key}`,
            fieldType: FieldType.QUESTION,
            mergeWith: null,
            customField: entity.schema === Schema.CUSTOM ? false : !!field.id,
          })
        }
      })
    },

    handleAllFieldsRemoval(entityId) {
      this.fieldInputs = this.fieldInputs.filter(input => (input.deselectable ? input.entityId !== entityId : true))
    },

    handleAccessibilityChange() {
      this.form.open = !this.form.open
      this.unlinkFields()
    },

    handleEntitySelection(entityId) {
      const entity = this.fieldOptionEntity(entityId)

      const requiredColumns = entity.fields.reduce((acc, field) => {
        const ignoredColumns = ['uuid_key']

        if (field.required && !ignoredColumns.includes(field.key)) {
          this.addSelectedField({
            entityId,
            fieldKey: field.key,
            deselectable: false,
          })

          acc.push({
            ...field,
            title: this.$t(field.label),
            entityId,
            schemaKey: entity.schema,
            tableKey: entity.key,
            fieldKey: field.key,
            hidden: false,
            hasDefaultValue: false,
            defaultValue: this.fieldDefaultValue(field),
            fieldId: `${entityId}.${field.key}`,
            fieldType: FieldType.QUESTION,
            mergeWith: null,
            customField: entity.schema === Schema.CUSTOM ? false : !!field.id,
          })
        }

        return acc
      }, [])

      this.fieldInputs.push(...requiredColumns)
    },

    handleEntityRemoval(entityId) {
      this.fieldInputs = this.fieldInputs.filter(
        item => item.entityId !== entityId,
      )
    },

    handleFieldSelection({ entityId, fieldKey }, forcedData = {}) {
      const entity = this.fieldOptionEntity(entityId)
      const field = entity.fields.find(_column => _column.key === fieldKey)

      this.fieldInputs.push({
        ...field,
        title: this.$t(field.label),
        entityId,
        schemaKey: entity.schema,
        tableKey: entity.key,
        fieldKey: field.key,
        hidden: false,
        hasDefaultValue: false,
        defaultValue: this.fieldDefaultValue(field),
        fieldId: `${entityId}.${field.key}`,
        fieldType: FieldType.QUESTION,
        mergeWith: null,
        customField: entity.schema === Schema.CUSTOM ? false : !!field.id,
        ...forcedData,
      })
    },

    handleQuestionFieldRemoval(field) {
      this.removeSelectedField(field)
      this.$nextTick(() => {
        this.handleFieldRemoval(field)
      })
    },

    handleTitleFieldRemoval(fieldId) {
      const index = this.fieldInputs.findIndex(e => fieldId === e.fieldId)
      this.fieldInputs.splice(index, 1)
    },

    handleFieldRemoval({ entityId, fieldKey }) {
      const index = this.fieldInputs.findIndex(
        e => entityId === e.entityId && fieldKey === e.fieldKey,
      )

      this.fieldInputs.splice(index, 1)
    },

    toggleSubmitConfigModal({ open }) {
      this.$root.$emit(RootEvents.FORMS_TOGGLE_SUBMIT_CONFIG_MODAL, {
        open,
        data: {
          textColor: this.form.submitLabelColorCode,
          bgColor: this.form.submitButtonColorCode,
          label: this.form.submitLabelText,
        },
      })
    },

    handleSubmitButtonUpdate(submit) {
      this.form.submitLabelColorCode = submit.textColor
      this.form.submitButtonColorCode = submit.bgColor
      this.form.submitLabelText = submit.label
    },

    prefillQuestionnaire(initialSelectedEntities) {
      const requiredColumnKeys = []
      this.fieldInputs = []

      this.bannerSrc = this.resource.bannerImageUrlInfo?.url

      this.form.open = this.resource.open
      this.form.title = this.resource.externalTitle
      this.form.description = this.resource.externalDescription
      this.form.submitLabelColorCode = this.resource.submitLabelColorCode
      this.form.submitButtonColorCode = this.resource.submitButtonColorCode
      this.form.submitLabelText = this.resource.submitLabelText

      initialSelectedEntities.forEach(entity => {
        this.toggleCollapsedState(entity.id)

        requiredColumnKeys.push(
          ...entity.columns
            .filter(({ required }) => required)
            .map(({ key }) => `${entity.id}.${key}`),
        )
      })

      this.resource.questionEntityBlocks.forEach(block => {
        const entityId = `${block.schemaKey}.${block.tableKey}`
        const fieldKey = block.fieldKey
        const columnKey = `${entityId}.${fieldKey}`

        const payload = {
          entityId,
          fieldKey,
          deselectable: !requiredColumnKeys.includes(columnKey),
        }

        this.addSelectedField(payload)

        this.handleFieldSelection(payload, {
          order: block.order,
          title: block.title,
          schemaKey: block.schemaKey,
          tableKey: block.tableKey,
          hidden: block.hidden,
          hasDefaultValue: !!block.defaultValue,
          defaultValue: block.defaultValue,
          uuidKey: block.uuidKey,
          fieldId: block.uuidKey,
          fieldType: FieldType.QUESTION,
          mergeWith: block.mergeWith,
          customField: block.schemaKey === Schema.CUSTOM ? false : !!block.entityFieldDetail?.id,
        })
      })

      this.resource.textBlocks.forEach(block => {
        this.addTitleBlock({
          order: block.order,
          title: block.title,
          description: block.description,
          uuidKey: block.uuidKey,
          fieldId: block.uuidKey,
          fieldType: FieldType.TITLE,
        })
      })

      this.fieldInputs.sort((a, b) => a.order - b.order)
      this.setLastSavedFieldInputs(this.$lodash.clone(this.fieldInputs))

      this.$nextTick(() => this.setForm(this.form))
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/master-variables';
@import '@/assets/scss/forms.scss';

#forms__questionnaire {
  width: 100%;

  .column__content {
    background: $forms__row-bg;

    .column__actions {
      margin-top: 24px !important;
      margin-bottom: 24px !important;

      .forms__accessibility-switch label {
        color: $forms__text-dark !important;
      }
    }

    .column__fields,
    .forms__submit-btn,
    .column__actions {
      margin: 32px 24px;

      @include breakpoint(1600px) {
        margin: 32px 48px;
      }

      @include breakpoint(1920px) {
        margin: 32px 64px;
      }

      @include breakpoint(2560px) {
        margin: 32px 128px;
      }
    }
  }

  @include xxl {
    margin-left: 1rem;
  }

  .ghost {
    .title__fields,
    .question-field__fields {
      border: 1px solid rgba($forms__border, 0.25);
      background: $forms__field-bg;
      visibility: visible;
      border-radius: 6px;
    }

    * {
      visibility: hidden;
    }
  }
}
</style>
