<template>
  <div class="form__question-field tw-flex tw-pb-4 tw-pr-4">
    <div class="question-field__handle tw-px-3">
      <ProzessDragHandle :draggable="draggable" class="mt-4 tw-pt-3" />
    </div>
    <div class="question-field__fields tw-flex tw-flex-col tw-w-full">
      <div class="question-field__title tw-w-full">
        <input
          type="text"
          :placeholder="$t('Title')"
          class="title__input tw-text-dark tw-w-full border-0 tw-outline-none"
          :value="field.title"
          @input="(e) => $emit('update:title', e.target.value)"
        >
        <hr class="title__underline">
      </div>
      <small
        v-if="titleError"
        class="text-danger tw-font-medium tw-text-2-1/2xs tw-mt-1"
      >
        {{ $t(titleError) }}
      </small>
      <div
        class="question-field__controls tw-flex tw-items-center tw-w-full tw-mt-3"
      >
        <b-form-datepicker
          v-if="field.type === CustomFieldTypes.Date"
          class="datepicker--forced-light"
          :disabled="!field.hasDefaultValue"
          :locale="$i18n.locale"
          :placeholder="$t('Default Value')"
          :value="field.defaultValue"
          @input="handleInput"
        />
        <prozess-field-wrapper
          v-else-if="field.type === CustomFieldTypes.List"
          :disabled="!field.hasDefaultValue"
          class="tw-w-full list--forced-light"
          icon="ListIcon"
        >
          <prozess-select
            :append-to-body="false"
            :disabled="!field.hasDefaultValue"
            class="tw-w-full"
            style="flex: 1"
            :placeholder="$t('Default Value')"
            :options="field.values"
            :label="$t('Default Value')"
            :value="field.defaultValue"
            @input="handleInput"
          />
        </prozess-field-wrapper>
        <b-form-checkbox
          v-else-if="field.type === CustomFieldTypes.Bool"
          class="tw-w-full"
          :checked="field.defaultValue"
          :disabled="!field.hasDefaultValue"
          switch
          inline
          @input="handleInput"
        >
          <span class="text-dark tw-text-2xs">
            {{ field.title }}
          </span>
        </b-form-checkbox>
        <prozess-field-wrapper
          v-else
          :disabled="!field.hasDefaultValue"
          class="tw-w-full input--forced-light"
          :icon="customFieldTypeIcons[field.type]"
        >
          <b-form-input
            ref="controlInput"
            :type="getInputType(field)"
            :disabled="!field.hasDefaultValue"
            :placeholder="$t('Default Value')"
            :value="field.defaultValue"
            @input="handleInput"
          />
        </prozess-field-wrapper>
        <div class="control__actions tw-flex-shrink-0 tw-pr-2">
          <template v-if="showEntityLinker(field)">
            <FormContactFieldLinker
              :id="`link-icon--${field.entityId}-${field.fieldKey}`"
              v-model="field.mergeWith"
              :field-type="field.type"
              :field-id="`${field.entityId}.${field.fieldKey}`"
              @input="(e) => $emit('update:mergeWith', e)"
            />
            <b-tooltip
              :target="`link-icon--${field.entityId}-${field.fieldKey}`"
              :title="$t('form-merge-with-tooltip')"
              placement="left"
            />
          </template>
          <feather-icon
            :id="`eye-icon--${field.entityId}-${field.fieldKey}`"
            class="mx-1 tw-cursor-pointer tw-text-dark"
            :icon="field.hidden ? 'EyeOffIcon' : 'EyeIcon'"
            size="16"
            :class="{
              'icon-disabled': !hideable,
            }"
            @click="hideable && updateHiddenness(!field.hidden)"
          />
          <b-tooltip
            :target="`eye-icon--${field.entityId}-${field.fieldKey}`"
            :title="showHideActionTooltip"
            placement="left"
          />
          <feather-icon
            :id="`type-icon--${field.entityId}-${field.fieldKey}`"
            class="mx-1 tw-cursor-pointer"
            :class="field.hasDefaultValue ? 'tw-text-primary' : 'tw-text-dark'"
            icon="TypeIcon"
            size="16"
            @click="handleDefaultValueToggled"
          />
          <b-tooltip
            :target="`type-icon--${field.entityId}-${field.fieldKey}`"
            :title="defaultValueActionTooltip"
            placement="left"
          />
          <feather-icon
            :id="`delete-icon--${field.entityId}-${field.fieldKey}`"
            :class="{
              'icon-disabled': isRequired,
            }"
            class="mx-1 tw-cursor-pointer tw-text-dark"
            icon="TrashIcon"
            size="16"
            @click="!isRequired && $emit('remove', field)"
          />
          <b-tooltip
            :target="`delete-icon--${field.entityId}-${field.fieldKey}`"
            :title="removeActionTooltip"
            placement="left"
          />
        </div>
      </div>
      <div
        class="tw-flex"
        :class="
          field.mergeWith || valueError
            ? 'tw-justify-between'
            : 'tw-justify-end'
        "
      >
        <small
          v-if="valueError"
          class="tw-flex-shrink-0 text-danger tw-font-medium tw-text-2-1/2xs tw-mt-1"
        >
          {{ $t(valueError) }}
        </small>
        <small
          v-if="field.mergeWith"
          class="text-success tw-font-medium tw-text-2-1/2xs tw-mt-1"
        >
          {{ $t('form-merge-with-text-1') }}
          {{ $t(getContactFieldLabel(field.mergeWith)) }}
          {{ $t('form-merge-with-text-2') }}
        </small>
        <small
          v-if="isRequired"
          class="tw-flex-shrink-0 tw-pl-4 tw-text-muted tw-font-medium tw-text-2-1/2xs tw-mt-1"
        >
          *{{ $t('Required') }}
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Entity, Schema } from '@/constants/app'
import CustomFieldTypes, {
  customFieldTypeIcons,
} from '@/constants/customFieldTypes'
import { getInputType } from '@/helpers/customFields'
import ProzessDragHandle from '@/components/shared/ProzessDragHandle.vue'
import FormContactFieldLinker from '@/components/Forms/FormContactFieldLinker.vue'

export default {
  components: {
    ProzessDragHandle,
    FormContactFieldLinker,
  },

  props: {
    draggable: {
      type: Boolean,
      default: true,
    },

    field: {
      type: Object,
      required: true,
    },

    titleError: {
      type: String,
      default: null,
    },

    valueError: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      Entity,
      Schema,
      CustomFieldTypes,
      customFieldTypeIcons,
    }
  },

  computed: {
    ...mapState({
      userFields: ({ user }) => user.fields,
      form: ({ form }) => form.form,
    }),

    isRequired() {
      return !this.field.deselectable
    },

    hideable() {
      return !this.requiredWithoutValue
    },

    requiredWithoutValue() {
      const hasDefaultValue = this.field.defaultValue === false ? true : !!this.field.defaultValue
      return this.isRequired && !hasDefaultValue
    },

    showHideActionTooltip() {
      if (this.requiredWithoutValue) {
        return this.$t('form-required-hidden-field-tooltip')
      }

      return this.field.hidden
        ? this.$t('Mark as visible')
        : this.$t('Mark as hidden')
    },

    defaultValueActionTooltip() {
      return this.field.hasDefaultValue
        ? this.$t('Unset default value')
        : this.$t('Set default value')
    },

    removeActionTooltip() {
      return this.isRequired
        ? this.$t('This is a required field')
        : this.$t('Remove')
    },
  },

  methods: {
    getContactFieldLabel(value) {
      const field = this.userFields.find(({ key }) => key === value)
      return field?.label ?? value
    },

    getInputType,

    handleDefaultValueToggled() {
      this.$emit('update:hasDefaultValue', !this.field.hasDefaultValue)
      this.$nextTick(() => {
        this.$refs.controlInput?.focus()

        this.$emit(
          'update:defaultValue',
          this.field.type === CustomFieldTypes.Bool ? false : null,
        )
      })
    },

    handleInput(e) {
      this.$emit('update:defaultValue', e)

      this.$nextTick(() => {
        if (this.requiredWithoutValue && this.field.hidden) {
          this.updateHiddenness(false)
        }
      })
    },

    showEntityLinker(field) {
      if (this.form.open) {
        return false
      }

      return (
        field.entityId === `${Schema.CRM}.${Entity.CONTACT}`
        || (field.entityId === `${Schema.CRM}.${Entity.COMPANY}`
          && field.fieldKey === 'name')
      )
    },

    updateHiddenness(hidden) {
      this.$emit('update:hidden', hidden)
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/forms.scss';

.form__question-field {
  background: $forms__row-bg;
  border-radius: 6px;

  .question-field__title {
    .title__underline {
      margin: 0;
      border-top: 1px dashed $forms__border;
      width: 200px;
    }

    .title__input {
      padding: 6px 0;
    }
  }

  .question-field__controls {
    min-height: 32px !important;
    border-radius: 6px;
    border: 1px solid $forms__border;
    background: $forms__field-bg;

    .control__input {
      padding: 8px;
    }
  }
}
</style>
