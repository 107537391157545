<template>
  <div id="forms__thank-you" class="forms__editor tw-mt-4">
    <div class="tw-mb-4 tw-flex tw-justify-between">
      <h4 class="mb-0 tw-text-body">{{ $t('Thank You Page') }}</h4>
    </div>
    <div
      class="column__content tw-flex tw-flex-col tw-justify-between tw-overflow-auto tw-rounded-lg tw-relative"
    >
      <div v-if="viewMode" class="selectors__backdrop tw-absolute" />
      <div class="column__content--inner">
        <div class="ty__title-heading mb-2">
          <h4 class="mb-1 tw-flex tw-items-center">
            {{ $t('Message Title') }}
          </h4>
          <hr class="my-0">
        </div>
        <prozess-input
          v-model="form.thankYouPageTitle"
          class="ty__title"
          type="text"
          icon="TypeIcon"
          field="thankYouPageTitle"
          :placeholder="$t('Title')"
          :error="$hasError('thankYouPageTitle')"
        />
        <div class="ty__msg-heading mb-2 mt-4">
          <h4 class="mb-1 tw-flex tw-items-center">
            {{ $t('Thank You Message') }}
          </h4>
          <hr class="my-0">
        </div>
        <prozess-quill-editor
          v-model="form.thankYouPageParagraph"
          :options="quillEditorConfig"
          class="mb-2"
          :placeholder="$t('Message')"
          :error="$hasError('thankYouPageParagraph')"
        />
      </div>
      <div class="ty__footer mb-3 tw-flex tw-justify-end">
        <b-button variant="outline-primary" @click="$emit('back')">
          {{ $t('Back') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { defaultThankYouForm } from '@/store/form/state'
import ProzessQuillEditor from '@core/components/ProzessQuillEditor.vue'
import RootEvents from '@/constants/rootEvents'

export default {
  components: {
    ProzessQuillEditor,
  },

  props: {
    viewMode: Boolean,

    errors: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      form: { ...defaultThankYouForm() },
      timeout: null,

      quillEditorConfig: {
        modules: {
          toolbar: [
            [{ size: ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline', { color: [] }],
            [{ list: 'bullet' }, { list: 'ordered' }],
            [{ align: [] }],
            ['clean'],
          ],
        },
      },
    }
  },

  computed: {
    ...mapState({
      resource: ({ form }) => form.resource,
    }),
  },

  watch: {
    form: {
      handler(value) {
        this.doDebounce('timeout', () => this.setThankyouForm(value), 300)
      },

      deep: true,
      immediate: true,
    },
  },

  mounted() {
    this.$root.$on(RootEvents.FORMS_PREFILL_DATA, e => {
      this.$nextTick(() => this.prefillForm(e))
    })
  },

  methods: {
    ...mapMutations({
      setThankyouForm: 'form/SET_THANKYOU_FORM',
    }),

    prefillForm() {
      this.form = this.$lodash.pick(this.resource, [
        'thankYouPageTitle',
        'thankYouPageParagraph',
      ])
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/master-variables';
@import '@/assets/scss/forms.scss';

#forms__thank-you {
  width: 100%;

  .column__content {
    background: var(--colour--card-bg);

    .column__content--inner,
    .ty__footer {
      margin: 32px 24px;

      @include breakpoint(1600px) {
        margin: 32px 48px;
      }

      @include breakpoint(1920px) {
        margin: 32px 64px;
      }

      @include breakpoint(2560px) {
        margin: 32px 128px;
      }
    }
  }
}
</style>
