<template>
  <div id="forms__form">
    <div class="search__actions tw-flex tw-justify-end tw-mb-2">
      <b-button
        v-if="resource"
        class="mr-1"
        variant="outline-secondary"
        @click="toggleShareModal({ open: true })"
      >
        <span class="align-middle">{{ $t('Share') }}</span>
      </b-button>
      <b-button
        v-if="viewMode && activeForm === FormType.QUESTIONNAIRE"
        variant="primary"
        class="mr-1"
        @click="activeForm = FormType.THANK_YOU"
      >
        <span class="align-middle">{{ $t('View Thank You Form') }}</span>
      </b-button>
      <b-button
        v-if="viewMode && activeForm === FormType.THANK_YOU"
        variant="primary"
        class="mr-1"
        @click="activeForm = FormType.QUESTIONNAIRE"
      >
        <span class="align-middle">{{ $t('View Questionnaire Form') }}</span>
      </b-button>
      <b-button
        v-if="formAction === FormAction.VIEW"
        id="forms__edit"
        class="mr-1"
        :class="{ 'icon-disabled': !canUpdate }"
        @click="
          canUpdate &&
            $router.push({
              name: 'form-view.edit',
              params: { id: resource.uuidKey },
            })
        "
      >
        <span class="align-middle">{{ $t('Edit') }}</span>
      </b-button>
      <b-tooltip v-if="!canUpdate" target="forms__edit" placement="left">
        {{ $t('no-edit-form-permission-text') }}
      </b-tooltip>
      <b-button
        v-if="showNextButton"
        :disabled="noInquiryContact || noTodoContact || noCompanyContactRelationship"
        class="mr-1"
        @click="handleNextClick"
      >
        <span class="align-middle">{{ $t('Next') }}</span>
      </b-button>
      <b-button
        v-if="!viewMode && activeForm === FormType.THANK_YOU"
        @click="handleSaveClick"
      >
        <b-spinner v-if="loading" small class="mr-1" />
        <span class="align-middle">{{ $t('Save') }}</span>
      </b-button>
    </div>
    <div class="forms__columns tw-flex">
      <div
        class="forms__column--selectors tw-flex"
        :class="{ 'pointer-events-none': disableSelectors }"
      >
        <ProzessEntitySelector
          title="Entities"
          :view-mode="disableSelectors"
          :options="entityOptions"
          :selections="selectedEntities"
          @item-click="(item) => handleTableClick(item)"
        />
        <ProzessEntityFieldSelector
          store="form"
          :view-mode="disableSelectors"
          :options="fieldOptions"
          @collapse-change="
            (collapsed) => (columns.tablesCollapsed = !collapsed)
          "
        />
      </div>
      <FormQuestionnaire
        v-show="activeForm === FormType.QUESTIONNAIRE"
        :view-mode="viewMode"
        :errors="questionnaireErrors"
      />
      <FormThankYouForm
        v-show="activeForm === FormType.THANK_YOU"
        :view-mode="viewMode"
        :errors="thankYouFormErrors"
        @back="activeForm = FormType.QUESTIONNAIRE"
      />
    </div>
    <FormShareModal />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import {
  formatStateAsCreationPayload,
  formatStateAsUpdatePayload,
} from '@/helpers/form'
import { questionnaireFormSchema, thankYouFormSchema } from '@/schema/form'
import { FieldType, FormAction } from '@/constants/form'
import ProzessEntitySelector from '@/components/shared/ProzessEntitySelector.vue'
import ProzessEntityFieldSelector from '@/components/shared/ProzessEntityFieldSelector.vue'
import authUserService from '@/services/authUser'
import formService from '@/services/form'
import prozessEntitySelectorMixin from '@/mixins/prozessEntitySelector'
import FormQuestionnaire from '@/components/Forms/FormQuestionnaire.vue'
import FormThankYouForm from '@/components/Forms/FormThankYouForm.vue'
import RootEvents from '@/constants/rootEvents'
import FormShareModal from '@/components/Forms/FormShareModal.vue'

const FormType = {
  QUESTIONNAIRE: 'questionnaire',
  THANK_YOU: 'thank-you',
}

export default {
  components: {
    ProzessEntitySelector,
    ProzessEntityFieldSelector,
    FormQuestionnaire,
    FormThankYouForm,
    FormShareModal,
  },

  mixins: [
    /**
     * !! ATTENTION !!
     * Read the comments on top of the `@/mixins/prozessEntitySelector.js` file first before implementing this mixin.
     *
     * DO NOT DELETE THIS COMMENT.
     */
    prozessEntitySelectorMixin('form'),
  ],

  data() {
    return {
      FormAction,
      loading: false,
      FormType,
      entityOptions: [],
      activeForm: FormType.QUESTIONNAIRE,
      questionnaireErrors: [],
      thankYouFormErrors: [],
    }
  },

  computed: {
    ...mapState({
      entities: ({ form }) => form.entities,
      selectedEntities: ({ form }) => form.selectedEntities,
      fieldOptions: ({ form }) => form.fieldOptions,
      banner: ({ form }) => form.banner,
      form: ({ form }) => form.form,
      fieldInputs: ({ form }) => form.fieldInputs,
      lastSavedFieldInputs: ({ form }) => form.lastSavedFieldInputs,
      thankYouForm: ({ form }) => form.thankYouForm,
      resource: ({ form }) => form.resource,
      removeBannerImage: ({ form }) => form.removeBannerImage,
    }),

    ...mapGetters({
      noInquiryContact: 'form/noInquiryContact',
      noTodoContact: 'form/noTodoContact',
      noCompanyContactRelationship: 'form/noCompanyContactRelationship',
    }),

    formAction() {
      switch (this.$route.name) {
        case 'forms.create':
          return FormAction.CREATE

        case 'form-view.edit':
          return FormAction.EDIT

        case 'form-view.view':
          return FormAction.VIEW

        default:
          throw new Error('Invalid Form Action')
      }
    },

    disableSelectors() {
      return this.viewMode || this.activeForm === FormType.THANK_YOU
    },

    showNextButton() {
      return this.activeForm === FormType.QUESTIONNAIRE && !this.viewMode
    },

    viewMode() {
      return ![FormAction.CREATE, FormAction.EDIT].includes(this.formAction)
    },

    canUpdate() {
      return this.$can('Update', 'Form')
    },
  },

  watch: {
    '$route.name': {
      handler(value) {
        if (!value.startsWith('form')) {
          return null
        }

        this.resetLocalState()
        this.resetStore()
        this.$nextTick(async () => {
          await this.getUserFields()
          await this.getEntities()

          if (this.$route.params.id) {
            this.getFormData()
          }
        })
      },

      immediate: true,
    },
  },

  destroyed() {
    /**
     * Unlisten to RootEvents.FORMS_PREFILL_DATA
     * declared at `FormQuestionnaire.vue` and `FormThankYouForm.vue`
     */
    this.$root.$off(RootEvents.FORMS_PREFILL_DATA)
  },

  methods: {
    ...mapMutations({
      setResource: 'form/SET_RESOURCE',
      setUserFields: 'user/SET_USER_FIELDS',
    }),

    // Override for prozessEntitySelector@addSelectedEntity
    addSelectedEntity(id) {
      this.$store.commit('form/ADD_SELECTED_ENTITY', id)

      this.$nextTick(() => {
        this.$root.$emit(RootEvents.FORMS_ADD_SELECTED_ENTITY, id)
      })
    },

    async getEntities() {
      const { response } = await this.$async(formService.getEntities())

      const entities = response.data.map(entity => {
        entity.id = `${entity.schema}.${entity.key}`
        return entity
      })

      this.setEntities(entities)
      this.resetEntityOptions()
    },

    async getFormData() {
      const { id } = this.$route.params
      const { response } = await this.$async(formService.getOne(id))

      this.setInitialData(response.data)
    },

    async getUserFields() {
      const { response } = await this.$async(authUserService.getUserFields())
      this.setUserFields(response.data)
    },

    async handleNextClick() {
      let questionsLength = 0

      const fieldInputs = this.fieldInputs.reduce((_fieldInputs, input) => {
        if (input.fieldType === FieldType.QUESTION) {
          ++questionsLength

          const prefix = `${input.entitySchema}_${input.entityKey}_${input.key}`

          _fieldInputs[`${prefix}_title`] = input.title
          _fieldInputs[`${prefix}_value`] = input.defaultValue
        }

        if (input.fieldType === FieldType.TITLE) {
          _fieldInputs[`${input.fieldId}_title`] = input.title
        }

        return _fieldInputs
      }, {})

      this.questionnaireErrors = await this.yupValidate(
        questionnaireFormSchema(this.fieldInputs),
        {
          ...this.form,
          ...fieldInputs,
          questionsLength,
        },
      )

      if (this.questionnaireErrors.length) {
        return null
      }

      this.activeForm = FormType.THANK_YOU
    },

    async handleSaveClick() {
      this.loading = true

      this.thankYouFormErrors = await this.yupValidate(
        thankYouFormSchema,
        this.thankYouForm,
      )

      if (this.thankYouFormErrors.length || this.noInquiryContact || this.noTodoContact || this.noCompanyContactRelationship) {
        this.loading = false
        return null
      }

      const formData = new FormData()

      const { file } = this.banner
      if (file) {
        formData.append('banner-image', file)
      }

      const payload = this.$route.params.id
        ? formatStateAsUpdatePayload({
          form: { ...this.form, ...this.thankYouForm },
          fieldInputs: this.fieldInputs,
          lastSavedFieldInputs: this.lastSavedFieldInputs,
          version: this.resource.version,
          removeBannerImage: this.removeBannerImage,
          bannerMimeType: file?.type,
        })
        : formatStateAsCreationPayload({
          form: { ...this.form, ...this.thankYouForm },
          fieldInputs: this.fieldInputs,
          bannerMimeType: file?.type,
        })

      formData.append('form', this.$blobify(payload))

      const request = this.$route.params.id
        ? formService.update(this.$route.params.id, formData)
        : formService.create(formData)

      const { response } = await this.$async(request)

      this.loading = false

      if (response) {
        this.$router.push({ name: 'forms' })
      }
    },

    // Override for prozessEntitySelector@removeSelectedEntity
    removeSelectedEntity(index) {
      const entityId = this.selectedEntities[index]

      this.$store.commit('form/REMOVE_SELECTED_ENTITY', index)

      this.$nextTick(() => {
        this.$root.$emit(RootEvents.FORMS_REMOVE_SELECTED_ENTITY, entityId)
      })
    },

    resetLocalState() {
      this.entityOptions = []
    },

    setInitialData(form) {
      this.setResource(form)

      this.$root.$emit(RootEvents.SET_PAGE_TITLE, form.name)

      const initialSelectedEntities = this.setInitialSelectedEntities(
        form.questionEntityBlocks,
      )

      this.$nextTick(() => this.$root.$emit(RootEvents.FORMS_PREFILL_DATA, initialSelectedEntities))
    },

    setInitialSelectedEntities(questionBlocks) {
      const entityIds = questionBlocks.map(
        ({ schemaKey, tableKey }) => `${schemaKey}.${tableKey}`,
      )

      const initialSelectedEntities = Array.from(new Set(entityIds)).map(
        id => ({
          ...this.entities.find(({ id: entityId }) => entityId === id),
          id,
        }),
      )

      initialSelectedEntities.forEach(entity => this.$nextTick(() => this.handleTableClick(entity)))

      return initialSelectedEntities
    },

    toggleShareModal({ open }) {
      this.$root.$emit(RootEvents.FORMS_TOGGLE_SHARE_MODAL, {
        open,
        url: this.resource.frontendUrl,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/mixins.scss';

@mixin column-content__min-height {
  @include xxl {
    $approx-title-height: 30px;
    height: calc(100% - #{$approx-title-height});
  }
}

#forms__form {
  .forms__columns {
    flex-direction: column;

    .forms__column--selectors {
      width: 100%;
      flex-direction: column;
    }

    .pz__entity-selector,
    .pz__entity-field-selector,
    .forms__editor,
    .forms__column {
      width: 100%;
    }
  }

  @include xxl {
    $approx-nav-footer-height: 210px;

    .forms__columns {
      min-height: calc(100vh - #{$approx-nav-footer-height});
      flex-direction: row;

      .forms__column--selectors {
        flex-direction: row;
        width: 50%;
      }

      .forms__editor {
        margin-left: 1rem;
      }

      .pz__entity-selector,
      .pz__entity-field-selector,
      .forms__editor,
      .forms__column {
        width: 50%;

        .column__content {
          @include column-content__min-height;
          overflow: hidden;
        }
      }

      .pz__entity-selector {
        margin-right: 0.5rem;
      }

      .pz__entity-field-selector {
        margin-left: 0.5rem;
      }
    }
  }
}
</style>
