<template>
  <b-modal
    id="form__submit-config-modal"
    v-model="open"
    hide-footer
    centered
    no-fade
    size="lg"
  >
    <div class="modal__body tw-p-8">
      <div class="modal__header d-flex align-items-center tw-mb-6">
        <h4 class="mb-2 modal__heading tw-flex tw-items-center">
          <feather-icon icon="SlidersIcon" class="mr-1" />
          {{ $t('Customize Submit Button') }}
        </h4>
      </div>

      <div>
        <prozess-input
          v-model="form.label"
          type="text"
          icon="TypeIcon"
          field="label"
          :placeholder="$t('Button Label')"
          :error="$hasError('label')"
        />
        <ColorPicker
          v-model="color"
          class="my-2"
          @input="form.bgColor = color.hex"
        />
        <prozess-input
          v-model="form.bgColor"
          type="text"
          icon="HashIcon"
          field="label"
          :placeholder="$t('Background Color')"
          :error="$hasError('bgColor')"
          @input="color.hex = form.bgColor"
        />
        <div class="tw-flex">
          <b-form-radio
            v-model="form.textColor"
            value="#ffffff"
            class="tw-flex tw-items-center mr-2"
          >
            <span class="tw-my-1 tw-inline-block">
              {{ $t('Use Light Text Color') }}
            </span>
          </b-form-radio>
          <b-form-radio
            v-model="form.textColor"
            value="#000000"
            class="tw-flex tw-items-center"
          >
            <span class="tw-my-1 tw-inline-block">
              {{ $t('Use Dark Text Color') }}
            </span>
          </b-form-radio>
        </div>
        <div class="config__preview my-3">
          <small class="tw-mb-4 tw-block text-uppercase text-center">
            {{ $t('Submit Button Preview') }}
          </small>
          <button
            class="btn tw-w-full hover:tw-bg-lighten tw-text-sm tw-py-4 shadow-sm"
            :style="submitBtnStyle"
          >
            {{ form.label }}
          </button>
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-center tw-mt-6">
        <button class="btn btn-dark tw-ml-3" type="button" @click="close">
          {{ $t('Cancel') }}
        </button>
        <button class="btn btn-secondary tw-ml-3" type="button" @click="save">
          {{ $t('Save') }}
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import RootEvents from '@/constants/rootEvents'
import { Swatches as ColorPicker } from 'vue-color'
import { submitButtonConfigSchema } from '@/schema/form'

const defaultColor = {
  hex: process.env.VUE_APP_COLOR_PRIMARY,
}

const defaultForm = {
  label: 'Submit',
  textColor: '#ffffff',
  bgColor: process.env.VUE_APP_COLOR_PRIMARY,
}

export default {
  components: {
    ColorPicker,
  },

  props: {
    saveCallback: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      open: false,
      errors: [],
      lightTextColor: false,
      form: { ...defaultForm },
      color: { ...defaultColor },
    }
  },

  computed: {
    submitBtnStyle() {
      return {
        color: this.form.textColor,
        backgroundColor: this.form.bgColor,
      }
    },
  },

  mounted() {
    this.registerBusEvent(
      RootEvents.FORMS_TOGGLE_SUBMIT_CONFIG_MODAL,
      ({ open, data }) => {
        this.open = open ?? true
        this.form = data
        this.color.hex = data.bgColor
      },
    )
  },

  destroyed() {
    this.$root.$off(RootEvents.FORMS_TOGGLE_SUBMIT_CONFIG_MODAL)
  },

  methods: {
    close() {
      this.open = false
    },

    async save() {
      this.errors = await this.yupValidate(submitButtonConfigSchema, this.form)
      if (this.errors.length) {
        return null
      }

      this.saveCallback(this.form)
      this.close()
    },
  },
}
</script>

<style lang="scss">
#form__submit-config-modal {
  .vc-swatches {
    background-color: transparent;
    box-shadow: none;
    width: 100%;
    height: auto;
    overflow: auto;
    padding: 0;

    .vc-swatches-box {
      padding: 0;
    }

    .vc-swatches-pick {
      display: none !important;
    }

    .vc-swatches-color-it:hover {
      transform: scale(1.2);
    }
  }
}
</style>
